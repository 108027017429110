import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import './scss/index.scss';
import './BtcRateContext';
import { BtcRateProvider } from './BtcRateContext';
import ReactGA from 'react-ga';


// Inicializace Google Analytics
const trackingId = "G-5K1DB3CWVF"; // Zde vložte vaše měřicí ID
ReactGA.initialize(trackingId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BtcRateProvider>
        <App />
      </BtcRateProvider>
    </BrowserRouter>
  </React.StrictMode>
);

